import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Message, MessageType } from '../models/message';
import { closeMessage } from '../store/message/message.actions';
import {Button} from "primeng/button";
import {Dialog} from "primeng/dialog";
import { selectMessageState } from '../store/message/message.selectors';

@Component({
  selector: 'app-modal-window',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './modal-window.component.html',
  styleUrls: ['./modal-window.component.scss'],
  imports: [
    Button,
    Dialog
  ],
  providers: [DynamicDialogRef, DynamicDialogConfig]
})
export class ModalWindowComponent implements OnInit {
    message: Message = new Message('', MessageType.INFO);
    visible: boolean = false;

    constructor(private store: Store,
                private changeDetectorRef: ChangeDetectorRef,
                public ref: DynamicDialogRef,
                public config: DynamicDialogConfig) {
    }

    ngOnInit(): void {
        this.store.select(selectMessageState).subscribe((state) => {
          if (!state){
            this.visible = true;
            this.changeDetectorRef.detectChanges();
            return;
          }
          this.message = state.message;
          this.visible = (this.message?.text || '').length > 0;
          this.changeDetectorRef.detectChanges();
        });
    }

    close(): void{
        this.store.dispatch(closeMessage());
        this.visible = false;
    }

}
