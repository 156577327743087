import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store} from '@ngrx/store';
import { selectAuthenticated } from './store/auth/auth.selectors';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AppGuard {

  constructor(private store: Store,
              private router: Router){
  }

  canActivate() {
    return this.store.pipe(
      select(selectAuthenticated),
      map(auth => auth || this.router.parseUrl('/signin'))
    );
  }

  canActivateChild() {
    return this.canActivate();
  }

}
