import { createReducer, on } from '@ngrx/store';
import { User } from '../../models/entity/user';
import {
  authSuccess,

  logout,
  resetGetCodeSuccess,
} from './auth.actions';

export interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
}

export const initialState: AuthState = {
  isAuthenticated: false,
  user: null
};

export const authReducer = createReducer(
  initialState,
  on( authSuccess, (state: AuthState, data: any) => ({
    ...state,
    isAuthenticated: true,
    user: (data.user as User)
  }) ),
  on( resetGetCodeSuccess, (state: AuthState, data: any) => ({
    ...state,
    isAuthenticated: false,
    user: { ...state.user, id: data.id } as User
  }) ),

  on( logout, (state: AuthState) => ({
    ...state,
    isAuthenticated: false
  }) )
);


