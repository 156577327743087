import { Component, OnInit } from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { PrimeNG } from 'primeng/config';
import {ModalWindowComponent} from "./modal-window/modal-window.component";
import {RouterOutlet} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  imports: [
    ModalWindowComponent,
    RouterOutlet,
    TranslateModule
  ],
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private config: PrimeNG, private translateService: TranslateService) {
  }

  ngOnInit() {
    this.translateService.setDefaultLang('ru');
    this.translate('ru')
  }

  translate(lang: string) {
    this.translateService.use(lang);
    this.translateService.get(lang).subscribe(res => this.config.setTranslation(res));
  }
}

