import { createReducer, on } from '@ngrx/store';
import { IMessage, MessageType } from '../../models/message';
import { closeMessage, openMessage } from './message.actions';

export interface MessageState {
    message: IMessage;
}

export const initialState: MessageState = {
    message: {
        messageType: MessageType.INFO
    },
};

export const messageReducer = createReducer(
    initialState,
    // @ts-ignore-all
    on(openMessage, (state: State, data: IMessage) => (
        { ...state,  message: data})
    ),
    // @ts-ignore-all
    on(closeMessage, (state: State) => (initialState)
    ),
);
