import { inject } from '@angular/core';
import { HttpEvent, HttpInterceptorFn } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { logout } from '../store/auth/auth.actions';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const store = inject(Store);
  let authToken = localStorage.getItem('accessToken');
  let handl: Observable<HttpEvent<any>>;
  if (authToken) {
    const cloned = req.clone({setHeaders: {
        "Authorization": `Bearer ${authToken}`,
        "Content-Type": 'application/json'
      }
    });

    handl = next(cloned);
  } else {
    handl = next(req);
  }

  return handl.pipe(
    catchError(error => {
      if (error.status === 401 || error.status === 403) {
        store.dispatch(logout());
        throw error;
      } else {
        throw error;
      }
    })
  );
};
