import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../../service/auth.service';
import { Router } from '@angular/router';
import { AuthActionTypes, authSuccess } from './auth.actions';
import { openMessage } from '../message/message.actions';
import { Store } from "@ngrx/store";
import { EmailOrPhone, PasswordForm, SigninForm, SignupForm, VerificationForm } from "../../login-screen/forms";
import { MessageType } from "../../models/message";

@Injectable()
export class AuthEffects {

  constructor (private actions$: Actions,
               private sessionService: AuthService,
               private router: Router,
               private store: Store) {
  }

  check: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.CHECK),
      switchMap(() => {
        const expiration = localStorage.getItem("expiration");
        if (!expiration) {
          localStorage.removeItem('accessToken');
          return EMPTY;
        }

        const exp = Number.parseInt(expiration);
        if (exp < Date.now()) {
          return this.sessionService.token().pipe(
            map(data => authSuccess(data)),
            catchError(() => {
              localStorage.removeItem('accessToken');
              return EMPTY;
            })
          );
        }

        const accessToken = localStorage.getItem("accessToken") || '';
        return of(authSuccess({ token: accessToken }));
      })
    )
  );

  login: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.LOGIN),
      switchMap((payload: SigninForm) =>
        this.sessionService.login(payload).pipe(
          map((data) => authSuccess(data)),
          tap(data => {
            localStorage.setItem('login', payload.emailOrPhone);
            this.updateToken(data.token);
            this.router.navigateByUrl('/app');
          })))));

  authSuccess: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.AUTH_SUCCESS),
      tap((data: any) => {
        this.updateToken(data.token);
        this.router.navigateByUrl('/app');
      })
    ), { dispatch: false }
  );

  logout: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.LOGOUT),
      switchMap(() =>
        this.sessionService.logout().pipe(
          tap(() => {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('expiration');
            this.router.navigateByUrl('/signin');
          }),
          catchError(() => EMPTY)
        )
      )
    ), { dispatch: false }
  );

  signup: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.SIGNUP),
      switchMap((payload: SignupForm) =>
        this.sessionService.signup(payload).pipe(
          map(() => {
            const { email, phone } = payload;
            const hasEmail = email.length > 0;
            const hasPhone = phone.length > 0;

            if (hasEmail) {
              localStorage.setItem('email', email);
              localStorage.setItem('emailCodeSent', Date.now().toString());
            }

            if (hasPhone) {
              localStorage.setItem('phone', phone);
              localStorage.setItem('phoneCodeSent', Date.now().toString());
            }

            const text = hasEmail && hasPhone
              ? 'На указанные вами email и номер отправлены коды подтверждения'
              : hasPhone
                ? 'На указанный вами номер отправлен код подтверждения'
                : 'На указанный вами email отправлен код подтверждения';

            this.router.navigateByUrl('/confirmation');

            return openMessage({
              text: `Аккаунт успешно создан\n${text}`,
              messageType: MessageType.INFO
            });
          })))));


  codeVerify: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.EMAIL_CODE_VERIFY, AuthActionTypes.PHONE_CODE_VERIFY),
      switchMap((payload: VerificationForm & {type: string}) => {
        return this.sessionService.verifyCode(payload).pipe(
          switchMap((data: any) => {
            const isPhone = payload.type === AuthActionTypes.PHONE_CODE_VERIFY;

            if (isPhone && (localStorage.getItem("email") || '').length > 0)
              this.router.navigate([], { queryParams: { type: 'email' } });
             else
              this.router.navigateByUrl('/signin');

            return of(openMessage({
              text: isPhone ? 'Ваш телефон успешно подтвержден' : 'Ваш email успешно подтвержден',
              messageType: MessageType.INFO
            }));
          }),
        );
      })
    ));

  resendCode: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.CODE_RESEND),
      switchMap((payload: EmailOrPhone) => {
        return this.sessionService.sendCode(payload);
      })
    ), { dispatch: false }
  );

  updateToken (token: string) {
    localStorage.setItem('accessToken', token);
    const exp = JSON.parse(atob(token.split('.')[1])).exp * 1000;
    const refreshTime = exp - Date.now() - 5 * 60 * 1000;
    localStorage.setItem('expiration', refreshTime.toString());
    setTimeout(() => this.store.dispatch({ type: AuthActionTypes.CHECK }), refreshTime);
  }


  /*
  *
  *
  *
  * */

  reset: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.RESET),
      switchMap((payload: EmailOrPhone) => {
        return this.sessionService.sendCode(payload).pipe(
          tap((data) => {
            let mode = data === 'PHONE_NUMBER' ? 'phone' : 'email';
            this.router.navigateByUrl("/reset?mode=" + mode);
          })
        );
      })), { dispatch: false });

  setPassword: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.SET_PASSWORD),
      switchMap((payload: PasswordForm) => {
        return this.sessionService.setPassword(payload).pipe(
          tap(() => {
            this.router.navigateByUrl("/signin");
          })
        );
      })), { dispatch: false });


  resetGetCodeSuccess: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.RESET_GET_CODE_SUCCESS),
      switchMap((payload: any) => {
        const data: any = { error: { errorMessage: 'На ваш телефон выслан код' } };
        return of(openMessage(data));
      })
    ),
  );

  resetSuccess: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.RESET_SUCCESS),
      switchMap((payload) => {
        this.router.navigateByUrl('/signin');
        const data: any = { error: { errorMessage: 'Пароль успешно установлен' } };
        return of(openMessage(data));
      })
    ),
  );

  resetFailure: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.RESET_FAILURE),
      switchMap((payload: any) => {
        return of(openMessage(payload.err));
      })
    ),
  );


}
