import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from "@angular/core";
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { TranslateModule, TranslateLoader, TranslateService, provideTranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation
} from "@angular/router";
import { provideStore } from "@ngrx/store";
import { authReducer } from "./store/auth/auth.reducer";
import { messageReducer } from "./store/message/message.reducer";
import { provideEffects } from "@ngrx/effects";
import { provideAnimations } from "@angular/platform-browser/animations";
import { AuthEffects } from "./store/auth/auth.effects";
import { NgxMaskConfig, provideEnvironmentNgxMask } from "ngx-mask";
import { providePrimeNG } from "primeng/config";
import { authInterceptor } from "./service/auth.interceptor";
import { routes } from "./app.routes";

const httpLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, './i18n/', '.json');

const maskConfig: Partial<NgxMaskConfig> = {
  validation: false,
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideHttpClient(withInterceptors([authInterceptor])),
    importProvidersFrom([TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    })]),
    provideRouter(routes, withEnabledBlockingInitialNavigation()),
    TranslateService,
    provideTranslateService(),
    providePrimeNG({
      theme: {
        options: {
          cssLayer: {
            name: 'primeng',
            order: 'primeng, maklersoft'
          }
        }

      }
    }),
    provideStore(
      { auth: authReducer, message: messageReducer }
    ),
    provideEnvironmentNgxMask(maskConfig),
    provideEffects([AuthEffects]),
    provideAnimations(),
  ],
};