import { Routes } from "@angular/router";
import { provideEffects } from "@ngrx/effects";
import { AuthEffects } from "./store/auth/auth.effects";
import { AppGuard } from "./app.guard";
import { provideState } from "@ngrx/store";
import { tabReducer } from "./store/tab/tab.reducer";
import { TabEffects } from "./store/tab/tab.effects";

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/app',
    pathMatch: 'full',
    providers: [
      provideEffects([AuthEffects]),
    ]
  },
  {
    path: 'signin', loadComponent: () => import( './login-screen/login-screen.component')
      .then(m => m.LoginScreenComponent)
  },
  {
    path: 'signup', loadComponent: () => import( './login-screen/login-screen.component')
      .then(m => m.LoginScreenComponent)
  },
  {
    path: 'reset', loadComponent: () => import( './login-screen/login-screen.component')
      .then(m => m.LoginScreenComponent)
  },
  {
    path: 'confirmation', loadComponent: () => import( './login-screen/login-screen.component')
      .then(m => m.LoginScreenComponent)
  },
  {
    path: 'app',
    canActivate: [AppGuard],
    canActivateChild: [AppGuard],
    providers: [
      provideState({ name: 'tab', reducer: tabReducer }),
      provideEffects([TabEffects]),
    ],
    loadComponent: () => import('./core/core.component').then(m => m.CoreComponent),
  }
];